import React from "react";
// import './App.scss';
import "./App.css";

import Navigation from "./components/Navigation";

const App = () => {
  return (
    <div>
      <Navigation />
    </div>
  );
};

export default App;
